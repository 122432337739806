<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <vuestic-widget :headerText="'Logs de accesos registrados en la zona'">
          <p>Acceso registrados en la zona</p>
          <div class="row">
            <div class="col-md-3">
              <button
                type="button"
                v-on:click="back"
                class="btn btn-primary btn-sm"
              >
                Volver
              </button>
            </div>

          
          </div>
        </vuestic-widget>
      </div>
      <div class="col-md-12">
        <TableZoneLog></TableZoneLog>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import TableZoneLog from "../tables/TableAccess/TableZoneLog.vue";

import { SpringSpinner } from "epic-spinners";

import { mapState, mapActions } from "vuex";

export default {
  name: "Table",
  components: {
    SpringSpinner,
    TableZoneLog,
  },
  data() {
    return {};
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    newZoneAccess() {
      this.$router.push("zoneaccess/new");
    },
  },
  computed: {
    ...mapState({
      statusEvent: (state) => state.event.event.status,
    }),
  },
};
</script>

<style lang="scss">
.color-icon-label-table {
  td:first-child {
    width: 1rem;
  }
}
</style>
