export default {
    tableFields:   [
        {
            name:      '__component:badge-column',
            title:     '',
            dataClass: 'text-center',
            width:     '4%'
        },
        {
            name:      'device.name',

            sortField: 'device.name',
            title:'Dispositivo',
            titleClass: "center aligned",
            dataClass:  "center aligned",
            
        },     
        {
            name:      'ticket.customer.name',
            sortField: 'ticket.customer.name',
            title:'Cliente',
            titleClass: "center aligned",
            dataClass:  "center aligned",
            
        },
        {
            name:      'staff.code',
            sortField: 'staff.code',
            title:'Staff',
            titleClass: "center aligned",
            dataClass:  "center aligned",
            
        },
        {
            name:      'timestamp_formated',
            sortField: 'timestamp_formated',
            title:'Fecha',
            titleClass: "center aligned",
            dataClass:  "center aligned",
            
        },
      
      
       /* {
            name:       '__slot:active',
            title:      'Active',
            title:'Estatus',
            titleClass: "center aligned",
            dataClass:  "center aligned",
           
        },
        {
            name:       '__slot:actions',
            title:      'Acciones',            
            titleClass: "center aligned",
            dataClass:  "center aligned",
           
        },*/
    ],
    sortFunctions: {
        'name': function (item1, item2) {
            return item1 >= item2 ? 1 : -1
        },
    }
}
